import { Injectable, OnDestroy } from '@angular/core';
import { Observable, of, pipe, Subject, throwError } from 'rxjs';
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { catchError, finalize, map, takeUntil } from 'rxjs/operators';
import { baseUrl } from '@environments/environment';
import { ToastService } from '@shared/services/toast/toast.service';
import { TranslateService } from '@ngx-translate/core';
import {
  RoutePlanningBase,
  CreateBasePayload,
  RoutePlanningRoute,
  CreateRoutePayload, RemoveBasePayload, RemoveRoutePayload
} from "@shared/models/route-planning";

@Injectable({
  providedIn: 'root'
})
export class RoutePlanningService implements OnDestroy {

  componentDestroyed$: Subject<boolean> = new Subject()

  constructor(
    private http: HttpClient,
    private toastService: ToastService,
    private translateService: TranslateService
  ) {}

  getBases(projectId: number): Observable<RoutePlanningBase[]> {
    const url = `${baseUrl}/api/bases/${projectId}`;

    return this.http.get<RoutePlanningBase[]>(url)
    .pipe(
      map((response: any) => {
        return response['message'].map((base: any) => ({
          id: base.id,
          coordinates: JSON.parse(base.coordinates),
          notes: base.notes
        })) as RoutePlanningBase[];
      }),
      catchError((error) => this.handleError(error))
    );
  }

  createBase(data: CreateBasePayload): Observable<RoutePlanningBase> {
    if (!data) this.throwBadRequestError();
    const url = `${baseUrl}/api/bases/createBase`;

    return this.http.post<RoutePlanningBase>(url, data).pipe(
      map((response: any) => {
        return {
          id: response.message.id,
          coordinates: JSON.parse(response.message.coordinates),
          notes: response.message.notes
        } as RoutePlanningBase;
      }),
      catchError((error: HttpErrorResponse) => this.handleError(error))
    );
  }

  getRoutes(projectId: number): Observable<RoutePlanningRoute[]> {
    const url = `${baseUrl}/api/routes/${projectId}`;

    return this.http.get<RoutePlanningRoute[]>(url)
      .pipe(
        map((response: any) => {
          return response['message'].map((route: any) => ({
            id: route.id,
            coordinates: JSON.parse(route.coordinates),
          })) as RoutePlanningRoute[];
        }),
        catchError((error) => this.handleError(error))
      );
  }

  createRoute(data: CreateRoutePayload): Observable<RoutePlanningRoute> {
    if (data?.coordinates?.length <= 1) this.throwBadRequestError();
    const url = `${baseUrl}/api/routes/createRoute`;

    return this.http.post<RoutePlanningRoute>(url, data).pipe(
      map((response: any) => {
        return {
          id: response.message.id,
          coordinates: JSON.parse(response.message.coordinates),
          notes: response.message.notes
        } as RoutePlanningRoute;
      }),
      catchError((error: HttpErrorResponse) => this.handleError(error))
    );
  }

  removeBase(data: RemoveBasePayload): Observable<boolean> {
    if (!data) this.throwBadRequestError();
    const url = `${baseUrl}/api/bases/removeBase`;

    return this.http.post<boolean>(url, data).pipe(
      map((response: any) => {
        return response.message as boolean
      }),
      catchError((error: HttpErrorResponse) => this.handleError(error))
    );
  }

  removeRoute(data: RemoveRoutePayload): Observable<boolean> {
    if (!data) this.throwBadRequestError();
    const url = `${baseUrl}/api/routes/removeRoute`;

    return this.http.post<boolean>(url, data).pipe(
      map((response: any) => {
        return response.message as boolean
      }),
      catchError((error: HttpErrorResponse) => this.handleError(error))
    );
  }

  private throwBadRequestError(): Observable<never> {
    return throwError(() => new HttpErrorResponse({
      error: { message: 'backend_errors.err_invalid_data' },
      status: 400,
      statusText: 'Bad Request'
    }));
  }

  private handleError(error: HttpErrorResponse): Observable<never> {
    let errorMessage = 'backend_errors.err_unknown';

    if (error?.message) {
      errorMessage = error.error.message;
    }

    errorMessage = this.translateService.instant(`backend_errors.${errorMessage}`);
    this.toastService.sendToast(false, errorMessage)
    return throwError(() => new Error(errorMessage));
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true)
    this.componentDestroyed$.complete()
  }
}
