<div class="row multi-select-search">
  <form *ngIf="form" [formGroup]="form">
    <div>
      <label>
        <input class="filter-checkbox" type="checkbox"
               [checked]="form.get('selected_type')?.value === searchTypes.WORK_ORDER"
               (change)="setSearchType(searchTypes.WORK_ORDER)">
        Work order
      </label>
      <label>
        <input class="filter-checkbox" type="checkbox"
               [checked]="form.get('selected_type')?.value === searchTypes.INVERTER"
               (change)="setSearchType(searchTypes.INVERTER)">
        Inverter
      </label>
      <label>
        <input class="filter-checkbox" type="checkbox"
               [checked]="form.get('selected_type')?.value === searchTypes.TYPE3"
               (change)="setSearchType(searchTypes.TYPE3)">
        Type3 Work Order
      </label>
      <label>
        <input class="filter-checkbox" type="checkbox"
               [checked]="form.get('selected_type')?.value === searchTypes.POWERLINE"
               (change)="setSearchType(searchTypes.POWERLINE)">
        Powerline
      </label>
    </div>

    <ng-template #filterNotFound>
      <ng-container *ngIf="isTypeFilterSelected()">{{ 'No filters available' | translate }}</ng-container>
    </ng-template>

    <div *ngIf="filtersExist(form.get('selected_type')?.value); else filterNotFound" formGroupName="filters">
      <ng-container [formGroupName]="form.get('selected_type')?.value">
        <ng-container *ngFor="let filterKey of formFilterKeys">
          <label>
            <input class="filter-checkbox"
                   type="checkbox"
                   [formControlName]="filterKey">
            {{ filterKey }}
          </label>
        </ng-container>

        <div class="search-count">
          {{ 'Found' | translate }}: {{ searchCount }}
        </div>
      </ng-container>
    </div>
  </form>
</div>
