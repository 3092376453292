import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'uniqueItems'
})
export class UniqueItemsPipe implements PipeTransform {
  transform(states: any[], field: string): any[] {
    return [...new Map(states.map(state => [state[field], state])).values()];
  }
}
