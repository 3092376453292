import { Injectable } from '@angular/core';
import 'leaflet-draw';
import { Layer } from "leaflet";
import { CustomerSettingsService } from "@shared/services/customerSettings/customer-settings.service";
import { RoutePlanningComponent } from "@modules/planner/components/route-planning/route-planning.component";
import { CustomerSettingsComponents } from "@shared/models/customer-settings";
declare const L: any;

@Injectable({providedIn: 'root'})
export class MapConfiguratorService {

  constructor(
    private customerSettingsService: CustomerSettingsService,
  ) {
  }

  private map!: L.Map;
  private editableLayers!: L.FeatureGroup;
  private drawControl: any;

  /**
   * Init leaflet map for customer
   * @param customerId
   * @param drawPluginOptions
   */
  public initMap(customerId: number, drawPluginOptions: any): L.Map {
    this.map = L.map('mapProject', { editable: true });
    this.map.zoomControl.setPosition('topright');

    L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      attribution: 'Map data &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>',
      maxZoom: 25,
      minZoom: 3,
      detectRetina: true,
    }).addTo(this.map);

    this.setMapCenter(customerId);

    this.editableLayers = new L.FeatureGroup();
    this.map.addLayer(this.editableLayers);

    const options = drawPluginOptions ? drawPluginOptions : this.getDefaultDrawPluginOptions();
    options.edit.featureGroup = this.editableLayers;

    this.drawControl = new L.Control.Draw(options);
    this.map.addControl(this.drawControl);

    return this.map;
  }

  private setMapCenter(customerId: number): void {
    const centerCoordinates = this.customerSettingsService
      .getCenterCoordinates(customerId, CustomerSettingsComponents.RoutePlanning);

    this.map.on('locationerror', () => {
      this.map.panTo({ lat: centerCoordinates.latitude, lng: centerCoordinates.longitude });
    });

    const initialCenter = L.latLng(centerCoordinates.latitude, centerCoordinates.longitude);
    if (customerId == 1) {
      this.map.setView(initialCenter, 16);
    } else {
      this.map.setView(initialCenter, 15);
    }
  }

  private getDefaultDrawPluginOptions(): any {
    return {
      position: 'topright',
      draw: {
        polygon: false,
        polyline: false,
        circle: false,
        rectangle: false,
        marker: false,
        circlemarker: false
      },
      edit: {
        remove: false,
        edit: false
      }
    };
  }

  disableZoom(): void {
    this.map.scrollWheelZoom.disable();
    this.map.doubleClickZoom.disable();
    this.map.touchZoom.disable();
    this.map.boxZoom.disable();
    this.map.keyboard.disable();
  }

  enableZoom(): void {
    this.map.scrollWheelZoom.enable();
    this.map.doubleClickZoom.enable();
    this.map.touchZoom.enable();
    this.map.boxZoom.enable();
    this.map.keyboard.enable();
  }

  getDrawControl(): any {
    return this.drawControl;
  }

  getLayers(): Layer[] {
    return this.editableLayers.getLayers();
  }

  getEditableLayers(): L.FeatureGroup {
    return this.editableLayers;
  }
}
