import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from '@core/auth/components/login/login.component';
import { AuthGuard } from '@core/guards/auth.guard';
import { PlannerComponent } from '@layout/planner/planner.component';
import { ProjectPageComponent } from '@modules/planner/components/project-page/project-page.component';
import { UserManagementComponent } from '@modules/planner/components/user-management/user-management.component';
import { PlannerManagementComponent } from '@modules/planner/components/planner-management/planner-management.component';
import { CallServiceManagementComponent } from '@modules/planner/components/callService-management/callService-management.component';
import { CustomerAdminManagementComponent } from '@modules/planner/components/customerAdmin-management/customerAdmin-management.component';
import { HumanResourcesComponent } from '@modules/planner/components/human-resources/human-resources.component';
import { WorkersComponent } from '@modules/planner/components/workers/workers.component';
import { WorkerDetailComponent } from '@modules/planner/components/worker-detail/worker-detail.component';
import { FieldReportingComponent } from '@layout/field-reporting/field-reporting.component';
import { DashboardComponent } from '@modules/field-reporting/components/dashboard/dashboard.component';
import { WeeklyPlanningMapComponent } from '@modules/planner/components/weekly-planning-map/weekly-planning-map.component';
import { ProjectDashboardComponent } from '@modules/planner/components/project-dashboard/project-dashboard.component';
import { ProjectScheduleComponent } from '@modules/planner/components/project-schedule/project-schedule.component';
import { ReportComponent } from '@modules/field-reporting/components/report/report.component';
import { TeamsComponent } from '@modules/planner/components/teams/teams.component';
import { TeamsDetailComponent } from '@modules/planner/components/teams-detail/teams-detail.component';
import { InterruptComponent } from '@modules/field-reporting/components/interrupt/interrupt.component';
import { ReportWorkorderDetailComponent } from '@modules/planner/components/report-workorder-detail/report-workorder-detail.component';
import { ForgotPasswordComponent } from '@core/auth/components/forgot-password/forgot-password.component';
import { PlannerDetailComponent } from '@modules/planner/components/planner-detail/planner-detail.component';
import { CallServiceDetailComponent } from '@modules/planner/components/callService-detail/callService-detail.component';
import {OverviewComponent} from "@layout/overview/overview.component";
import {OverviewDashboardComponent} from "@modules/overview/components/overview-dashboard/overview-dashboard.component";
import {RoutePlanningComponent} from "@modules/planner/components/route-planning/route-planning.component";
/**
 * This is our routing module which handles the routing of our application.
 */

const routes: Routes = [
  {
    path: '',
    component: LoginComponent,
  },
  {
    path: 'forgot-password',
    component: ForgotPasswordComponent
  },
  {
    path: 'planner',
    component: PlannerComponent,
    canActivate: [ AuthGuard ],
    canActivateChild: [ AuthGuard ],
    data: {
      role: 1
    },
    children: [
      {
        path: '',
        redirectTo: 'dashboard',
        pathMatch: 'full'
      },
      {
        path: 'dashboard',
        component: ProjectDashboardComponent,
        data: {
          role: 1
        }
      },
      {
        path: 'schedule',
        component: ProjectScheduleComponent,
        data: {
          role: 1
        }
      },
      {
        path: 'project/:newproject',
        component: ProjectPageComponent,
        data: {
          role: 1
        }
      },
      {
        path: 'project',
        component: ProjectPageComponent,
        data: {
          role: 1
        }
      },
      {
        path: 'user-management',
        component: UserManagementComponent,
        data: {
          role: 1
        }
      },
      {
        path: 'planner-management',
        component: PlannerManagementComponent,
        data: {
          role: 1
        }
      },
      {
        path: 'callservice-management',
        component: CallServiceManagementComponent,
        data: {
          role: 1
        }
      },
      {
        path: 'admin-management',
        component: CustomerAdminManagementComponent,
        data: {
          role: 1
        }
      },
      {
        path: 'human-resources',
        component: HumanResourcesComponent,
        data: {
          role: 1
        }
      },
      {
        path: 'workers',
        component: WorkersComponent,
        data: {
          role: 1
        }
      },
      {
        path: 'teams',
        component: TeamsComponent,
        data: {
          role: 1
        }
      },
      {
        path: 'teams-detail/:id',
        component: TeamsDetailComponent,
        data: {
          role: 1
        }
      },
      {
        path: 'worker-detail/:id',
        component: WorkerDetailComponent,
        data: {
          role: 1
        }
      },
      {
        path: 'planner-detail/:id',
        component: PlannerDetailComponent,
        data: {
          role: 1
        }
      },
      {
        path: 'callService-detail/:id',
        component: CallServiceDetailComponent,
        data: {
          role: 1
        }
      },
      {
        path: 'weekly-planning',
        component: WeeklyPlanningMapComponent,
        data: {
          role: 1
        }
      },
      // {
      //   path: 'weekly-planning-detail/:id',
      //   component: WeeklyPlanningDetailComponent,
      //   resolve: {
      //     userInfo: UserInfoResolver
      //   },
      //   data: {
      //     role: 1
      //   }
      // },
      // {
      //   path: 'weekly-planning-map/:id',
      //   component: WeeklyPlanningMapComponent,
      //   data: {
      //     role: 1
      //   }
      // },
      {
        path: 'report-workorder-detail/:id',
        component: ReportWorkorderDetailComponent,
        data: {
          role: 1
        }
      },
      {
        path: 'route-planning',
        component: RoutePlanningComponent,
        data: {
          role: 1
        }
      },
    ]
  },{
    path: 'field-reporting',
    component: FieldReportingComponent,
    canActivate: [ AuthGuard ],
    canActivateChild: [ AuthGuard ],
    data: {
      role: 2
    },
    children: [
      {
        path: '',
        redirectTo: 'dashboard',
        pathMatch: 'full'
      },
      {
        path: 'dashboard',
        component: DashboardComponent,
        data: {
          role: 2
        }
      },
      {
        path: 'report/:id',
        component: ReportComponent,
        data: {
          role: 2
        }
      },
      {
        path: 'interrupt/:id',
        component: InterruptComponent,
        data: {
          role: 2
        }
      }
    ]
  },
  {
    path: 'overview',
    component: OverviewComponent,
    canActivate: [ AuthGuard ],
    canActivateChild: [ AuthGuard ],
    data: {
      role: 3
    },
    children: [
      {
        path: 'dashboard',
        component: OverviewDashboardComponent,
        data: {
          role: 3
        }
      },
    ]
  },
  {
    path: '**',
    redirectTo: ''
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
