export interface WorkOrder {
  reservations?: { [key: number]: Reservation };
  report_type?: number;
  wid?: number;
  reported?: any | null;
  deviation: any | null;
  slots: any;
  id: number;
  rid?: number;
  identifier?: string;
  project_id: number;
  msa_id?: number;
  latitude?: number;
  longitude?: number;
  status_id: number;
  notes?: string;
  time_parameters?: string;
  interrupted: boolean;
  statuses: { [statusId: string]: Status };
  scheduled?: any;
  created: Date;
  modified?: Date;
  removed?: Date;
  transformer?: string; // Add transformer property
  inverter?: string;    // Add inverter property
  circuit?: string;     // Add circuit property
  state: number;
  type: number;
  showInMap: boolean;
  comment: number;
  team_status_id?: number | null;
  tightening?: boolean;
}

export enum WorkOrderSearchTypes {
  'STANDARD' = 'STANDARD',
  'INVERTER' = 'INVERTER',
  'TYPE3' = 'TYPE3',
}
export interface WorkOrderSearch extends WorkOrder {
  search_type?: WorkOrderSearchTypes;
}

interface Reservation {
    csid: number;
    starttime: string;
    endtime: string;
}
interface Status {
  count?: number;
  images: Image[];
}
interface Image {
  id: number;
  title: string;
  status_id: string;
  url?: string;
}
