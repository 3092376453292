<div class="page-container">
  <div class="page-heading">
    <h3>
      <img class="nav-icon" src="assets/icons/route_planner.svg">
      <span class="page-title">{{ 'sideNavigation.titleRoutePlanning' | translate }}</span>
    </h3>
    <div class="actions">
      <button *ngIf="activeAction === actions.NONE"
              (click)="enableCreateBase()"
              class="btn btn-primary">
        <img class="action-icon" src="assets/icons/add_white_24dp.svg">
        {{ 'planner.routePlanning.createBase' | translate }}
      </button>
      <ng-container *ngIf="activeAction === actions.CREATE_BASE">
        <span class="action-help-text me-2">
          {{ 'planner.routePlanning.clickToCreateBase' | translate }}
        </span>
        <button (click)="cancelAction()"
                class="btn btn-secondary">
          {{ 'basic.cancel' | translate }}
        </button>
      </ng-container>

      <button *ngIf="activeAction === actions.NONE"
              (click)="enableDrawRoute()"
              class="btn btn-primary">
        <img class="action-icon" src="assets/icons/edit_white_24dp.svg">
        {{ 'planner.routePlanning.drawRoute' | translate }}
      </button>
      <ng-container *ngIf="activeAction === actions.DRAW_ROUTE">
        <span class="action-help-text me-2">
          {{ 'planner.routePlanning.enterToSaveRoute' | translate }}
        </span>
        <button (click)="cancelAction()"
                class="btn btn-secondary">
          {{ 'basic.cancel' | translate }}
        </button>
      </ng-container>
    </div>
    <div class="tools-info">
      <span class="info"><img class="base-icon pe-1" src="assets/icons/powerlines/base.png">
        {{ 'planner.routePlanning.base' | translate }}
      </span>
      <span class="info"><span class="lines pe-1">---</span>
        {{ 'planner.routePlanning.route' | translate }}
      </span>
    </div>
  </div>
  <div class="page-content">
    <div class="map-height map-container">
      <div id="mapProject"></div>
    </div>
  </div>
</div>
