import { Injectable } from '@angular/core';
import { RectangleSelection } from "@shared/models/general";
import { WorkOrder } from "@shared/models/work-order";
import { Subject } from "rxjs";
declare const L: any;

@Injectable({providedIn: 'root'})
export class RectangleSelectService {

	private selectedWorkOrdersSubject = new Subject<WorkOrder>();
	private map!: L.Map;
	private workOrders: WorkOrder[] = [];

	public opacity = 1;
	public color = '#111111';
	public selectedWorkOrders$ = this.selectedWorkOrdersSubject.asObservable();
	public shiftPressed = false;
	public rectangleSelection: RectangleSelection = {
		enabled: false,
		startPoint: null,
		rectangle: null
	};

	register(map: L.Map): void {
		this.map = map;
		this.map.boxZoom.disable();
		this.map.on('keydown', (event: L.LeafletKeyboardEvent) => this.onKeyDown(event));
		this.map.on('keyup', (event: L.LeafletKeyboardEvent) => this.onKeyUp(event));
		this.map.on('mousedown', (event: L.LeafletMouseEvent) => this.onMouseDown(event));
		this.map.on('mousemove', (event: L.LeafletMouseEvent) => this.onMouseMove(event));
		this.map.on('mouseup', () => this.onMouseUp());
	}

	setSelectableWorkOrders(workOrders: WorkOrder[]): void {
		this.workOrders = workOrders;
	}

	setHighlightColor(color: string, opacity: number): void {
		this.color = color;
		this.opacity = opacity;
	}

	private disableMapTools(): void {
		this.map.dragging.disable();
		this.map.doubleClickZoom.disable();
		this.map.scrollWheelZoom.disable();
	}

	private enableMapTools(): void {
		this.map.dragging.enable();
		this.map.doubleClickZoom.enable();
		this.map.scrollWheelZoom.enable();
	}

	private onKeyDown(event: L.LeafletKeyboardEvent): void {
		if (event.originalEvent.code === 'ShiftLeft' && !this.shiftPressed) {
			this.shiftPressed = true;
			this.disableMapTools();
		}
	}

	private onKeyUp(event: L.LeafletKeyboardEvent): void {
		if (event.originalEvent.code === 'ShiftLeft' && this.shiftPressed) {
			this.shiftPressed = false;
			this.enableMapTools();
		}
	}

	private onMouseDown(event: L.LeafletMouseEvent): void {
		if (event.originalEvent.shiftKey) {
			this.rectangleSelection.enabled = true;
			this.rectangleSelection.startPoint = event.latlng;
		}
	}

	private onMouseMove(event: L.LeafletMouseEvent): void {
		if (this.rectangleSelection.enabled && this.rectangleSelection.startPoint) {
			const bounds = L.latLngBounds(this.rectangleSelection.startPoint, event.latlng);

			if (this.rectangleSelection.rectangle) {
				this.rectangleSelection.rectangle.setBounds(bounds);
				return;
			}

			this.rectangleSelection.rectangle = L.rectangle(bounds, { color: 'gray', weight: 1 }).addTo(this.map);
		}
	}

	private onMouseUp(): void {
		if (this.rectangleSelection.enabled) {
			if (this.rectangleSelection.rectangle) {
				this.rectangleSelectItems(this.rectangleSelection.rectangle);
				this.map.removeLayer(this.rectangleSelection.rectangle);
				this.rectangleSelection.rectangle = null;
			}

			this.rectangleSelection.enabled = false;
			this.rectangleSelection.startPoint = null;
			this.rectangleSelection.rectangle = null;
		}
	}

	private rectangleSelectItems(rectangle: L.Rectangle): void {
		const bounds = rectangle.getBounds();

		for (const workOrder of this.workOrders) {
			const latLng = {
				lat: Number(workOrder.latitude),
				lng: Number(workOrder.longitude)
			};

			if (bounds.contains(latLng)) {
				this.selectedWorkOrdersSubject.next(workOrder);
			}
		}
	}
}
