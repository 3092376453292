<div class="search">
    <img class="search-icon" src="assets/icons/search.svg" alt="search">
    <img *ngIf="searchResultsCount()"
         (click)="close()"
         class="search-close"
         src="assets/icons/close_red_24dp.svg"
         alt="search-close">
    <input class="search-input" [formControl]="searchControl" placeholder="Search by identifier..." />

    <ol *ngIf="searchResultsCount()" class="help-text">
        <li *ngFor="let workOrder of workOrdersFiltered | slice:0:maxResults; let i = index"
             class="search-item">
            <span (click)="click(workOrder)" class="help-text-open ms-1">
                Frame #{{ workOrder.identifier }}
            </span>
            <img (click)="goToLocation(workOrder)"
                 class="search-location"
                 src="assets/icons/location.svg"
                 alt="search-location">
        </li>
    </ol>
</div>
