import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { WorkOrder } from "@shared/models/work-order";
import { WorkOrderStatus } from "@shared/models/status";
import { AbstractControl, FormBuilder, FormGroup } from "@angular/forms";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { MultiSelectSearch } from "@shared/models/multi-select-search";

@Component({
  selector: 'app-multi-select-search',
  templateUrl: './multi-select-search.component.html',
  styleUrls: ['./multi-select-search.component.scss']
})
export class MultiSelectSearchComponent implements OnInit {

  @Input() workOrders: WorkOrder[] = [];
  @Input() inverters: WorkOrder[] = [];
  @Input() type3WorkOrders: WorkOrder[] = [];
  @Input() powerLineWorkOrders: WorkOrder[] = [];
  @Input() states: WorkOrderStatus[] = [];
  @Input() showDoneOnly = true;
  @Output() onChange = new EventEmitter<WorkOrder[]>();
  @Output() onClick = new EventEmitter<WorkOrder>();
  @Output() onLocationClick = new EventEmitter<WorkOrder>();

  private takeUntilDestroyed$ = takeUntilDestroyed();
  private combinedWorkOrders: WorkOrder[] = [];

  public form!: FormGroup;
  public formFilterKeys: string[] = [];
  public formFiltersMap: { [key: number]: { [key: string]: WorkOrderStatus } } = {};
  public workOrdersFiltered: WorkOrder[] = [];
  public searchCount = 0;
  public searchTypes = MultiSelectSearch;

  constructor(
    private fb: FormBuilder,
  ) { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.combineData();

    if (changes['states']?.currentValue) {
      this.initFormGroup();
    }
  }

  private combineData(): void {
    this.combinedWorkOrders = [
      ...this.workOrders.map(order => ({ ...order })),
      ...this.inverters.map(order => ({ ...order })),
      ...this.type3WorkOrders.map(order => ({ ...order })),
      ...this.powerLineWorkOrders.map(order => ({ ...order })),
    ];
    console.warn('comb', this.combinedWorkOrders, this.states)
  }

  /**
   * Build form controllers -> { filters: { [type]: { [definition]: boolean } } }
   * @private
   */
  private initFormGroup(): void {
    let formFields = {};

    this.states.forEach(state => {
      const key = this.toUnderscore(state.definition);

      if (!formFields[state.type]) formFields[state.type] = {};
      formFields[state.type][key] = [false];

      if (!this.formFiltersMap[state.type]) this.formFiltersMap[state.type] = {};
      state.workorders.doneidsmap = new Map(state.workorders.doneids.map(id => [id, true]));
      this.formFiltersMap[state.type][key] = state;
    });

    Object.keys(formFields).forEach(type => {
      formFields[type] = this.fb.group(formFields[type]);
    });

    const form = {
      selected_type: [0],
      filters: this.fb.group(formFields),
    };

    this.form = this.fb.group(form);
    this.onFormValueChange();
    console.warn('form', this.form, this.formFiltersMap)
  }

  private onFormValueChange(): void {
    this.form.valueChanges
    .pipe(this.takeUntilDestroyed$)
    .subscribe(() => {
      this.setFormFilters(this.form.get('selected_type')?.value);
      this.workOrdersFiltered = this.filter();
      console.warn('this.workOrdersFiltered', this.form, this.workOrdersFiltered)
    });
  }

  /**
   * Set available statuses for the selected work order type & show them as filters
   * @param type
   * @private
   */
  private setFormFilters(type: number): void {
    this.formFilterKeys = [];
    const filters = this.getFiltersByType(type);
    if (filters) this.formFilterKeys = Object.keys(filters.controls);
  }

  private getFiltersByType(type: number): FormGroup {
    return this.form.get('filters')?.get(String(type)) as FormGroup;
  }

  public filtersExist(type: number): boolean {
    return !!this.getFiltersByType(type);
  }

  public isTypeFilterSelected(): boolean {
    return this.form.get('selected_type')?.value;
  }

  private toUnderscore(str: string): string {
    return str.replace(/\s+/g, '_').toLowerCase();
  }

  /**
   * Filter work orders by work order type and status
   * If no status selected -> show all with same type
   * @private
   */
  private filter(): WorkOrder[] {
    const results: WorkOrder[] = [];
    this.searchCount = 0;

    for (let workOrder of this.combinedWorkOrders) {
      const selectedType = this.form.get('selected_type')?.value;

      if (
        selectedType === this.searchTypes.WORK_ORDER && workOrder.type == 1
        || selectedType === this.searchTypes.INVERTER && workOrder.type == 2
        || selectedType === this.searchTypes.TYPE3 && workOrder.type == 3
        || selectedType === this.searchTypes.POWERLINE && (workOrder.type == 4 || workOrder.type == 5)
      ) {
        const filters = this.getFiltersByType(workOrder.type);
        if (!filters) continue;

        let hasFilterSelected = false;
        for (let filterKey of Object.keys(filters.controls)) {
          const value = filters.get(filterKey)?.value;
          if (!value) continue;

          hasFilterSelected = true;
          const status = this.formFiltersMap[workOrder.type]?.[filterKey];
          if (!status) continue;

          if (workOrder.status_id == status.id) {
            if (!this.showDoneOnly) {
              results.push(workOrder);
              continue;
            }

            // show only work orders that have been reported DONE
            if (status.workorders.doneidsmap?.get(workOrder.id)) results.push(workOrder);
          }
        }

        if (!hasFilterSelected) results.push(workOrder);
      }
    }

    this.searchCount = results.length;
    return results;
  }

  setSearchType(value: number) {
    const currentValue = this.form.value.selected_type;
    this.form.patchValue({
      selected_type: currentValue === value ? null : value
    });
  }

  ngOnDestroy(): void {
  }

}
