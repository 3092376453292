import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { baseUrl, integUrl } from '@environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { ToastService } from '@shared/services/toast/toast.service';
import { of } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { catchError, map } from 'rxjs/operators';
import {Status} from "@shared/models/status";
import {Project} from "@shared/models/project";

@Injectable({
  providedIn: 'root'
})
export class PlannerProjectService {

  constructor(
    private http: HttpClient,
    private toastService: ToastService,
    private translateService: TranslateService
  ) { }

  currentProjectId: any
  projectData: any

  /**
   * Perform get api call to backend with route baseUrl/api/projects to
   * get all projects from backend
   * @returns all projects from database
   */
  getProjects(): Observable<any[]> {
    return this.http.get<any[]>(`${baseUrl}/api/projects`)
      .pipe(
      map((data: any[]) => {
        return data['message'];
    }));
  }

    getProjectStatus(id: number, type: number): Observable<any> {
      let params = new HttpParams()
      params = params.set('type', type)
        return this.http.get<any>(`${baseUrl}/api/projectstatus/${id}`, {params: params})
            .pipe(
                map((data: any) => {
                    return data['message'];
                })
            );
    }

  /**
   * Returns all data about one project.
   * If everything is okay, you'll get a 200 OK response.
   * If projects is not found, the request will fail with 404 error
   * @param id project id
   * @returns all data for project
   */
  getProjectById(id: number): Observable<any> {
    const url = `${baseUrl}/api/projects/${id}`;
    return this.http.get<any>(url).pipe(
        map((data: any) => {
            return data['message'];
        }),
        catchError(error => {
            console.error('getProjectById - error:', error); // Log any errors
            return of(null); // Return a fallback observable in case of error
        })
    );
}


  /**
   * Deletes the project from backend.
   * If everything is okay, you'll get a 200 OK response.
   * If projects is not found, the request will fail with 404 error
   * @param id project id
   */
  deleteProjectById(id: number): Observable<any> {
    return this.http.delete<any>(`${baseUrl}/api/projects/${id}`)
      .pipe(
        map((data: any) => {
          return data['message'];
        })
      );
  }

  /**
   * Gets full
   * @param id
   * @returns
   */
  getProjectTime(id: number, type: number) {
    let params = new HttpParams()
    params = params.set('type', type)
    return this.http.get<any>(`${baseUrl}/api/projecttime/${id}`, {params: params})
      .pipe(
        map((data: any) => {
          //console.log(data)
          return data['message'];
        })
      );
  }

  updateProjectTimeEstimates(id, itemToUpdate): Observable<any> {

    let newdataJSON = {
      "default_time_parameters": itemToUpdate
    }

    let newdata = JSON.stringify(newdataJSON)

    return this.http.patch(`${baseUrl}/api/projects/${id}`, {
        newdata
      })
  }

  updateDefaultGroupSizes(id, itemToUpdate): Observable<any> {

    let newdataJSON = {
      "default_group_sizes": itemToUpdate
    }

    let newdata = JSON.stringify(newdataJSON)

    return this.http.patch(`${baseUrl}/api/projects/${id}`, {
        newdata
      })
  }

    updateProjectStatuses(statusesToUpdate): Observable<any> {
        if(statusesToUpdate) {
            let newdata = JSON.stringify(statusesToUpdate)
            return this.http.patch(`${baseUrl}/api/statuses/`, {
                newdata
            })
        }
        return of(true);

  }

  uploadMail(id, itemToUpdate): Observable<any> {

    let newdataJSON = {
      "communications": itemToUpdate
    }

    let newdata = JSON.stringify(newdataJSON)

    return this.http.patch(`${baseUrl}/api/projects/${id}`, {
        newdata
    }).pipe(
      map((data: any) => {
        this.toastService.sendToast(true, this.translateService.instant('services.mailTextSaved'))
        return data['message'];
      }),
      catchError(error => {
        this.toastService.sendToast(false, this.translateService.instant('services.mailTextError'))
        //console.log(error)
        return of(false);
      })
    )
  }

  uploadMailImage(id, logoData): Observable<any> {

    const formData = new FormData()

    formData.append('logo', logoData as any)

    return this.http.post(`${baseUrl}/api/uploadimage/${id}`, formData)
    .pipe(
      map((data: any) => {
        this.toastService.sendToast(true, this.translateService.instant('services.logoSavedSuccess'))
        return data['message'];
      }),
      catchError(error => {
        this.toastService.sendToast(false, this.translateService.instant('services.logoError'))
        return of(false);
      })
    )
  }

  /**
   * 1.
   *
   * /api/project
   *
   * When time parameters have been set in frontend, we call this method and send a post request to
   * Backend responds
   * @param data
   * @returns
   *
   * @edit 7.12.2022
   * Added generateMeters field so that backend knows when to generate meters based off "address, zip, city" data
   * @param generateMeters boolean value for generating meters
   * @author Jesse Lindholm
   *
   * @edit 3.1.2023
   * Removed generateMeters parameter
   * @param generateMeters Removed
   * @author Jesse Lindholm
   */
  createProject(name, customer, information, type, time, communications):Observable<any>{

    let formGroup = new UntypedFormGroup({
      name: new UntypedFormControl(name, [Validators.required]),
      customer: new UntypedFormControl(customer, [Validators.required]),
      information: new UntypedFormControl(information),
      project_type: new UntypedFormControl(type, [Validators.required]),
      time_window: new UntypedFormControl(time, [Validators.required]),
      communications: new UntypedFormControl(communications, [Validators.required])
    })

    return this.http.post<any>(`${baseUrl}/api/projects`, formGroup.value)
      .pipe(
        map((data: any) => {
          return data['message'];
        }),
        catchError(error => {
          //alert(error.error)
          return of(false)
        })
      )
  }

  /**
   * 1.
   *
   * The next 3 methods are all part of creating a project. !!
   *
   * /api/confirmdata/
   *
   * This one sends the excel file to backend and returns a response.
   * @param file
   * @returns
   */
  uploadExcel(file: File): Observable<any> {
    const formData: FormData = new FormData();

    formData.append('xlsxfile', file);

    return this.http.post<any>(`${integUrl}/api/confirmdata/`, formData, {
      reportProgress: true,
      responseType: 'json'
    }).pipe(
        map((data: any) => {
          return data['message'];
        }),
        catchError(error => {
          //console.log(error)
          return of(false)
        })
      )
  }

  /**
   * 2.
   *
   * /api/returnuniquequeue
   *
   * This method sends values set by the user. Backend uses these values to read the excel and act accordingly.
   * Backend responds with 'time parameter' headlines where the user can set new time parameters.
   * This method requires 3 params.
   *
   * ufile (confirmdatassa ladatun tiedoston nimi)
   * headerline (millä rivillä on otsikot)
   * startat (miltä riviltä aloitetaan datan lukeminen)
   * @param data
   * @returns
   */
  returnUnique(ufile, headerline, startat):Observable<any>{

    const formData: FormData = new FormData();

    formData.append('ufile', ufile);
    formData.append('headerline', headerline);
    formData.append('startat', startat);

    return this.http.post<any>(`${integUrl}/api/returnuniquequeue`, formData)
      .pipe(
        map((data: any) => {
          return data['message'];
        }),
        catchError(error => {
          //console.log(error)
          return of(false)
        })
      )
  }

  /**
   * 3.
   *
   * /api/import
   *
   * This method takes headline array as parameter. Headline array consists of columns that the user has selected from the excel he has imported.
   * These selections help us to match excel columns with db columns.
   *
   * @param data
   * @returns
   */
  importHeadlines(ufile, headerline, startat, headlineArray, generateMeters: boolean) {
    const formData: FormData = new FormData();

    formData.append('startat', startat);
    formData.append('ufile', ufile);
    formData.append('generateMeters', JSON.stringify(generateMeters))
    formData.append('project_id', headlineArray['project_id']);
    formData.append('headerline', headerline);

    if(headlineArray['lat']) formData.append('lat', headlineArray['lat'])
    if(headlineArray['lon']) formData.append('lon', headlineArray['lon'])
    if(headlineArray['zip']) formData.append('zip', headlineArray['zip'])
    if(headlineArray['city']) formData.append('city', headlineArray['city'])
    if(headlineArray['ordergroup']) formData.append('ordergroup', headlineArray['ordergroup'])
    if(headlineArray['workorder_identifier']) formData.append('workorder_identifier', headlineArray['workorder_identifier'])
    if(headlineArray['time_parameter_columns']) formData.append('time_parameter_columns', headlineArray['time_parameter_columns'])
    if(headlineArray['headerline']) formData.append('headerline', headlineArray['headerline'])
    if(headlineArray['address']) formData.append('address', headlineArray['address'])
    if(headlineArray['notes']) formData.append('notes', headlineArray['notes'])
    if(headlineArray['access_type']) formData.append('access_type', headlineArray['access_type'])
    if(headlineArray['access_type_notes']) formData.append('access_type_notes', headlineArray['access_type_notes'])
    if(headlineArray['location_number']) formData.append('location_number', headlineArray['location_number'])
    if(headlineArray['old_device_id']) formData.append('old_device_id', headlineArray['old_device_id'])
    if(headlineArray['worker_notes']) formData.append('worker_notes', headlineArray['worker_notes'])
    if(headlineArray['fuse_limit']) formData.append('fuse_limit', headlineArray['fuse_limit'])
    if(headlineArray['fuse_type']) formData.append('fuse_type', headlineArray['fuse_type'])
    if(headlineArray['story']) formData.append('story', headlineArray['story'])
    if(headlineArray['placement']) formData.append('placement', headlineArray['placement'])
    if(headlineArray['contact_info']) formData.append('contact_info', headlineArray['contact_info'])
    // Owner
    if(headlineArray['owner_firstname']) formData.append('owner_firstname', headlineArray['owner_firstname'])
    if(headlineArray['owner_lastname']) formData.append('owner_lastname', headlineArray['owner_lastname'])
    if(headlineArray['owner_address']) formData.append('owner_address', headlineArray['owner_address'])
    if(headlineArray['owner_zip']) formData.append('owner_zip', headlineArray['owner_zip'])
    if(headlineArray['owner_city']) formData.append('owner_city', headlineArray['owner_city'])
    if(headlineArray['owner_phone']) formData.append('owner_phone', headlineArray['owner_phone'])
    // Resident
    if(headlineArray['resident_firstname']) formData.append('resident_firstname', headlineArray['resident_firstname'])
    if(headlineArray['resident_lastname']) formData.append('resident_lastname', headlineArray['resident_lastname'])
    if(headlineArray['resident_address']) formData.append('resident_address', headlineArray['resident_address'])
    if(headlineArray['resident_zip']) formData.append('resident_zip', headlineArray['resident_zip'])
    if(headlineArray['resident_city']) formData.append('resident_city', headlineArray['resident_city'])
    if(headlineArray['resident_phone']) formData.append('resident_phone', headlineArray['resident_phone'])

    return this.http.post<any>(`${integUrl}/api/importqueue`, formData)
      .pipe(
        map((data: any) => {
          return data['message'];
        }),
        catchError(error => {
          //console.log(error)
          return of(false)
        })
      )
  }


  importOrdergroups(ufile, startat, headlineArray, userId, generateCoordinates):Observable<any>{

    const formData: FormData = new FormData();

    formData.append('ufile', ufile);
    formData.append('startat', startat);
    formData.append('identifier', headlineArray['identifier']);
    formData.append('generateCoordinates', JSON.stringify(generateCoordinates) );

    if(headlineArray['lat']) formData.append('lat', headlineArray['lat'])
    if(headlineArray['lon']) formData.append('lon', headlineArray['lon'])
    if(headlineArray['msa_id']) formData.append('msa_id', headlineArray['msa_id'])
    formData.append('uid', userId.toString())

    return this.http.post<any>(`${integUrl}/api/importordergroups`, formData)
      .pipe(
        map((data: any) => {
          return data['message'];
        }),
        catchError(error => {
          //console.log(error)
          return of(false)
        })
      )
  }

  getQueueStatus(status) {
    return this.http.get<any>(`${integUrl}/api/queuestatus/${status}`)
      .pipe(
        map((data: any) => {
          return data['message'];
        })
      );
  }

  getQueue(id) {
    return this.http.get<any>(`${integUrl}/api/queues/${id}`)
      .pipe(
        map((data: any) => {
          return data['message'];
        })
      );
  }

  getQueues() {
    return this.http.get<any>(`${integUrl}/api/queues`)
      .pipe(
        map((data: any) => {
          return data['message'];
        })
      );
  }

  /**
   * 25.11.2022
   * Update project's barcodes field
   * @param projectId Id of project
   * @param barcodes Array of barcodes
   * @author Jesse Lindholm
   */
  updateProjectBarcodes(projectId, barcodes) {
    let newdataJSON = {
      "project_barcodes": barcodes
    }

    let newdata = JSON.stringify(newdataJSON)
    if (projectId && barcodes) {
      return this.http.patch(`${baseUrl}/api/projects/${projectId}`, {newdata})
      .pipe(
        map(data => {
          this.toastService.sendToast(true, this.translateService.instant('services.barcodesUpdateSuccess'))
          return data
        }),
        catchError(() => {
          this.toastService.sendToast(false, this.translateService.instant('services.barcodesUpdateFailed'))
          return of(false)
        })
      )
    } else {
      this.toastService.sendToast(false, this.translateService.instant('services.barcodesUpdateFailed'))
      return of(false)
    }
  }

  /**
   * 5.12.2022
   * Create barcodes for project
   * @param projectId project ID
   * @param barcodes Array of barcodes
   * @returns Observable of HTTP call
   * @author Jesse Lindholm
   */
  createProjectBarcodes(projectId, barcodes) {
    let newDataJSON = {
      "project_barcodes": barcodes
    }

    let newdata = JSON.stringify(newDataJSON)

    if (projectId && barcodes) {
      return this.http.patch(`${baseUrl}/api/projects/${projectId}`, {newdata})
      .pipe(
        map(data => {
          this.toastService.sendToast(true, this.translateService.instant('services.barcodesCreateSuccess'))
          return data
        }),
        catchError(() => {
          this.toastService.sendToast(false, this.translateService.instant('services.barcodesCreateFailed'))
          return of(false)
        })
      )
    } else {
      this.toastService.sendToast(false, this.translateService.instant('services.barcodesCreateFailed'))
      return of(false)
    }
  }

  /**
   * 10.2.2023
   * Update project's sms messages
   * @param projectId Id of project
   * @param messages Array of sms messages
   * @param settings Other sms messages, such as sender
   * @author Jesse Lindholm
   */
  updateProjectSmss(projectId, messages, settings) {

    let smsMessages = {'messages': messages, 'sender': settings.sender, 'timezone': settings.timezone}
    let newdataJSON = {
      "sms": JSON.stringify(smsMessages)
    }
    let newdata = JSON.stringify(newdataJSON)

    if (projectId) {
      return this.http.patch(`${baseUrl}/api/projects/${projectId}`, {newdata})
        .pipe(
          map(data => {
            this.toastService.sendToast(true, this.translateService.instant('services.smsMessagesUpdateSuccess'))
            return data
          }),
          catchError(() => {
            this.toastService.sendToast(false, this.translateService.instant('services.smsMessagesUpdateFailed'))
            return of(false)
          })
        )
    } else {
      this.toastService.sendToast(false, this.translateService.instant('services.smsMessagesUpdateFailed'))
      return of(false)
    }
  }

  /**
   * Returns all data about one status.
   * If everything is okay, you'll get a 200 OK response.
   * If status is not found, the request will fail with 404 error
   * @param id project id
   * @returns all data for statuses
   */
  getStatusByWorkorderId(id: number): Observable<any> {
    return this.http.get<any>(`${baseUrl}/api/statusbyworkorder/${id}`)
      .pipe(
        map((data: any) => {
          return data['message'];
        })
      );
  }

  getStatuses(projectId?: number): Observable<any> {
    const url = `${baseUrl}/api/statuses`;

    // If a projectId is provided, it's appended as a query parameter
    if (projectId) {
      return this.http.get(url, {
        params: {
          project_id: projectId.toString()
        }
      });
    }

    return this.http.get(url);
  }



  /**
   * 20.4.2023
   * Update project's information
   * @param projectId Id of project
   * @param messages Array of sms messages
   */
  updateProjectInformation(projectId, information) {

    let newdataJSON = {
      "information": information
    }
    let newdata = JSON.stringify(newdataJSON)

    if (projectId) {
      return this.http.patch(`${baseUrl}/api/projects/${projectId}`, {newdata})
        .pipe(
          map(data => {
            this.toastService.sendToast(true, this.translateService.instant('services.InfoUpdateSuccess'))
            return data
          }),
          catchError(() => {
            this.toastService.sendToast(false, this.translateService.instant('services.InfoUpdateFailed'))
            return of(false)
          })
        )
    } else {
      this.toastService.sendToast(false, this.translateService.instant('services.InfoUpdateFailed'))
      return of(false)
    }
  }

    getSchedule() {
        const url = `${baseUrl}/api/schedule/`;
        return this.http.get(url)
            .pipe(
                map((data: any) => {
                    return data['message'];
                }),
                catchError(this.handleError(`getSchedule`))
            );
    }

    private handleError<T>(operation = 'operation', result?: T) {
        return (error: any): Observable<T> => {

            //console.log(`${operation} failed: ${error.message}`);

            // Let the app keep running by returning an empty result.
            return of(result as T);
        };
    }
}
