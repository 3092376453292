// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false
};
//export const baseUrl = `https://dev.internal.solar-sky.eu`
//export const integUrl = `https://dev.internal.solar-sky.eu`
export const baseUrl = `http://localhost:8000`
export const integUrl = `http://localhost:9000`