import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from '@shared/components/header/header.component';
import { TranslateModule } from '@ngx-translate/core';
import { ToastItemComponent } from '@shared/components/toast-item/toast-item.component';
import { ToastComponent } from '@shared/components/toast/toast.component';
import { SpinnerComponent } from '@shared/components/spinner/spinner.component';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { FieldReportingHeaderComponent } from './components/field-reporting-header/field-reporting-header.component';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { NgxCircleCustomComponent } from './components/ngx-circle-custom/ngx-circle-custom.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CheckboxComponent } from './components/checkbox/checkbox.component';
import { WeekPickerComponent } from './components/week-picker/week-picker.component';
import { SearchComponent } from "@shared/components/search/search.component";
import { MultiSelectSearchComponent } from "@shared/components/multi-select-search/multi-select-search.component";


/**
 * Shared stuff is stuff that every user eventually needs. (Footer, header, some services etc.)
 * This is the core module for shared stuff. This binds all the shareable logic.
 * Declares all components that are shared, then imports modules that have a more global job.
 */

@NgModule({
  declarations: [
    HeaderComponent,
    ToastItemComponent,
    ToastComponent,
    FieldReportingHeaderComponent,
    SpinnerComponent,
    NgxCircleCustomComponent,
    CheckboxComponent,
    WeekPickerComponent,
    SearchComponent,
    MultiSelectSearchComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    SweetAlert2Module.forRoot(),
    // Specify ng-circle-progress as an import
    NgCircleProgressModule.forRoot({
      // set defaults here
      radius: 20,
      outerStrokeWidth: 8,
      innerStrokeWidth: 2,
      outerStrokeColor: "#78C000",
      innerStrokeColor: "#C7E596",
      animationDuration: 300,
      showSubtitle: false
    }),
    ReactiveFormsModule,
  ],
  exports: [
    ToastComponent,
    HeaderComponent,
    FieldReportingHeaderComponent,
    SpinnerComponent,
    NgxCircleCustomComponent,
    CheckboxComponent,
    WeekPickerComponent,
    SearchComponent,
    MultiSelectSearchComponent,
  ]
})
export class SharedModule { }
