import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Sentry from "@sentry/angular";

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import 'zone.js';

// Initialize Sentry as early as possible
let sentryDSN = environment.sentry
let sentrySessionSampleRate = environment.production ? 0.5 : 0.1
let sentryBackendURL = environment.sentryBackendURL
Sentry.init({
  dsn: sentryDSN,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  tracePropagationTargets: sentryBackendURL,
  // Session Replay
  replaysSessionSampleRate: sentrySessionSampleRate, // 10% for development, adjust in production
  replaysOnErrorSampleRate: 1.0, // 100% session replay on errors
  beforeSend(event, hint) {
    // Suppress all user-facing popups
    // IMPORTANT
    // Disable this return statement if you want to debug in localhost
    if (window.location.hostname === 'localhost') {
      return null; // Ignore events in development
    }
      
    return event;
  },
});


if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
