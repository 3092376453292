import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl } from "@angular/forms";
import { debounceTime, distinctUntilChanged, Subject } from "rxjs";
import { WorkOrder, WorkOrderSearch } from "@shared/models/work-order";

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit, OnChanges {

  @Input() workOrders: WorkOrder[] = [];
  @Input() inverters: WorkOrder[] = [];
  @Input() type3WorkOrders: WorkOrder[] = [];
  @Input() strictSearch = true;
  @Input() maxResults = 5;
  @Output() onChange = new EventEmitter<WorkOrderSearch[]>();
  @Output() onClick = new EventEmitter<WorkOrderSearch>();
  @Output() onLocationClick = new EventEmitter<WorkOrderSearch>();

  private combinedWorkOrders: WorkOrderSearch[] = [];
  public searchControl = new FormControl('');
  public searchText: string = '';
  public workOrdersFiltered: WorkOrderSearch[] = [];

  constructor() {
    this.searchControl.valueChanges
      .pipe(debounceTime(300), distinctUntilChanged())
      .subscribe(value => {
        if (!this.strictSearch && !value) {
          this.close();
          this.onChange.emit([]);
          return;
        }

        this.searchText = this.processSearchValue(value);
        this.workOrdersFiltered = this.strictSearch
          ? this.filterStrict(this.searchText)
          : this.filter(this.searchText);

        this.onChange.emit(this.workOrdersFiltered);
    });
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.combineData();
  }

  private combineData(): void {
    this.combinedWorkOrders = [
      ...this.workOrders.map(order => ({ ...order, search_type: 'STANDARD' }) as WorkOrderSearch),
      ...this.inverters.map(order => ({ ...order, search_type: 'INVERTER' }) as WorkOrderSearch),
      ...this.type3WorkOrders.map(order => ({ ...order, search_type: 'TYPE3' }) as WorkOrderSearch)
    ];
  }

  private processSearchValue(search: string | null): string {
    return search?.trim() ?? '';
  }

  private filter(search: string): WorkOrderSearch[] {
    return this.combinedWorkOrders.filter(
      workOrder => workOrder.identifier?.indexOf(String(search)) !== -1
    );
  }

  private filterStrict(search: string): WorkOrderSearch[] {
    return this.combinedWorkOrders.filter(
      workOrder => workOrder.identifier === search
    );
  }

  searchResultsCount(): number
  {
    return this.workOrdersFiltered.length;
  }

  click(workOrder: WorkOrderSearch): void {
    this.onClick.emit(workOrder);
  }

  close(): void {
    this.searchControl.setValue('');
    this.searchText = '';
    this.workOrdersFiltered = [];
  }

  goToLocation(workOrder: WorkOrderSearch): void {
    this.onLocationClick.emit(workOrder);
  }

}
