export enum RoutePlanningActions {
  'NONE' = 'NONE',
  'CREATE_BASE' = 'CREATE_BASE',
  'DRAW_ROUTE' = 'DRAW_ROUTE',
  'EDIT_ROUTE' = 'EDIT_ROUTE',
}

export interface LeafletDrawEvent {
  layer: L.Layer;
  layerType: string;
  sourceTarget: any;
  target: any;
  type: string;
}

export interface CreateBasePayload {
  project_id: number;
  lat: number;
  lng: number;
  note: string;
}

export interface CreateRoutePayload {
  project_id: number;
  coordinates: { lat: number, lng: number }[];
}

export interface RemoveBasePayload {
  project_id: number;
  base_id: number;
}

export interface RemoveRoutePayload {
  project_id: number;
  route_id: number;
}

export interface RoutePlanningBase {
  id: number;
  project_id?: number;
  coordinates: { lat: number, lng: number };
  notes: string;
}

export interface RoutePlanningRoute {
  id: number;
  project_id?: number;
  coordinates: { lat: number, lng: number }[];
}

export const ROUTE_PLANNER_POLYLINE_COLOR = '#72ff16';
export const ROUTE_PLANNER_POLYLINE_EDIT_COLOR = 'red';
export const ROUTE_PLANNER_POLYLINE_HIGHLIGHT_COLOR = 'blue';

export const ROUTE_PLANNER_POLYLINE_SHAPE_OPTIONS = {
  color: ROUTE_PLANNER_POLYLINE_COLOR,
  weight: 4,
  opacity: 1,
  fillOpacity: 1,
  dashArray: '10, 10',
}

export const ROUTE_PLANNER_POLYLINE_SHAPE_EDIT_OPTIONS = {
  maintainColor: false,
  color: ROUTE_PLANNER_POLYLINE_EDIT_COLOR,
  opacity: 0.7,
  fillOpacity: 0.7
}

export const ROUTE_PLANNER_DRAW_OPTIONS = {
  position: 'topright',
  draw: {
    polygon: false,
    polyline: false,
    circle: false,
    rectangle: false,
    marker: false,
    circlemarker: false
  },
  edit: {
    remove: false,
    edit: false
  }
};
