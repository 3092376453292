<div *ngIf="showDashboard">
    <nav class="navbar navbar-expand ps-3 project-select dual-container cursor-pointer">
        <div class="project dropdown">
            <a href="#" data-bs-toggle="dropdown" aria-expanded="false">
                <div class="project-menu">
                    <span class="projectName">{{currentProject}}</span>
                </div>
            </a>
            <ul class="project-dropdown dropdown-menu dropdown-menu-end" aria-labelledby="projectDropdownMenuButton">
                <li id="projectDropdownMenuItems" *ngFor="let item of projects">
                    <a class="dropdown-item" (click)="switchProject(item.id)">{{item.name}}</a>
                </li>
            </ul>
        </div>
        <div class="right">
            <img src="assets/icons/expand_more_gray_24dp.svg">
        </div>
    </nav>
    <div class="dual-container">
        <nav class="workerteam navbar navbar-expand left">
            <div class="project dropdown">
                <a href="#" data-bs-toggle="dropdown" aria-expanded="false">
                    <div class="project-menu">
                        <div class="d-flex" *ngIf="currentWorkerteam">
                            <div class="workerteam-name fw-bold">{{currentWorkerteam.values.name}}</div>
                        </div>
                    </div>
                </a>
                <h2 *ngIf="!currentWorkerteam">{{'workers.noWorkerteamsFound' | translate }}</h2>
                <ul class="project-dropdown dropdown-menu dropdown-menu-end"
                    aria-labelledby="projectDropdownMenuButton">
                    <li id="workerteamsDropdownMenuItems" *ngFor="let item of workerteams">
                        <a class="dropdown-item" *ngIf="item.show" (click)="switchWorkerteam(item.id)">{{item.values.name}}</a>
                    </li>
                </ul>
            </div>
        </nav>
    </div>


    <div class="main">
        <nav class="nav justify-content-between pt-3">
            <a class="ms-3 nav-tab" [ngStyle]="styleObject(0)" (click)="tabChanger(0)">Map</a>
            <a class="me-3 nav-tab" [ngStyle]="styleObject(3)" (click)="tabChanger(3)">{{'basic.done' | translate }}</a>
        </nav>

        <hr>
        <div [hidden]="tab !== 0" class="map-height">
            <div #mapmap id="mapmap" class="map-container structure-child">
                <!-- Work Order Details View -->
                <div *ngIf="selectedWorkOrder" class="workorder-details-container pb-5 d-flex flex-column">
                    <div class="dual-container">
                        <div class="left">Selected</div>
                        <div class="close-icon-container right">
                            <img src="assets/icons/close_gray_24dp.svg" alt="Close" (click)="closeDetails()"
                                class="close-icon">
                        </div>
                    </div>
                    <div class="inner-workorder-details p-2 w-100">
                        <div class="workorder-text-details">
                            <div class="fw-bold blue-text">Details
                                <div class="d-flex">
                                    <p class="col-6">ID:
                                        <span *ngIf="customerId !== 2 && customerId !== 4" class="gray-text">{{ selectedWorkOrder.id }}</span>
                                        <span *ngIf="customerId === 2 && selectedWorkOrder.transformer" class="gray-text">{{ selectedWorkOrder.transformer }} - {{selectedWorkOrder.inverter}}<span *ngIf="selectedWorkOrder.circuit"> - {{selectedWorkOrder.circuit}}</span></span>
                                        <span *ngIf="customerId === 2 && !selectedWorkOrder.transformer" class="gray-text">{{selectedWorkOrder.identifier}}</span>
                                        <span *ngIf="customerId === 4" class="gray-text">{{selectedWorkOrder.transformer}} - {{selectedWorkOrder.inverter}} - {{selectedWorkOrder.circuit}}</span>
                                    </p>
                                    <div class="buttons col-6 d-flex justify-content-end">
                                        <button *ngIf="showUpdateButton(selectedWorkOrder)"  (click)="toSingleWorkOrder(selectedWorkOrder, currentWorkerteam.id, 'update')"
                                            class="btn btn-primary btn-sm">Update report</button>
                                        <button *ngIf="showCreateButton(selectedWorkOrder)" (click)="toSingleWorkOrder(selectedWorkOrder, currentWorkerteam.id, 'create')"
                                            class="btn btn-primary btn-sm">Create Report</button>
                                    </div>
                                </div>
                                <p>Status: <span class="gray-text">{{ getStatusName(selectedWorkOrder.status_id) }}</span></p>
                                <p>Notes: <span class="gray-text">{{ selectedWorkOrder.notes }}</span></p>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div *ngIf="tab === 3">
            <div class="pt-2">
                <div class="ml-5 pb-1 row justify-content-between">
                    <div class="col-4">
                        <span class="bold-text">{{tabTitle}}</span>
                        <span class="gray-text"> ({{displayedWorkOrders.length}})</span>
                    </div>
                </div>
                <div *ngIf="displayedWorkOrders.length > 0 && !this.workOrdersLoading">
                    <div *ngFor="let item of displayedWorkOrders" class="content sharp-shadow rounded-card-sm mb-2"
                        [ngClass]="{'reserved': item.reserver_id && item.reserver_id != this.userid}">
                        <div class="p-2">
                            <div (click)="toSingleWorkOrder(item, currentWorkerteam.id, 'done')" [ngClass]="{ 'cursor-pointer': showUpdateButton(item) }">
                                <div class="dual-container p-1">
                                    <div class="left text-uppercase">
                                        <div class="text-lowercase">
                                            <span class="text-capitalize">{{'basic.workorder' | translate }}</span>
                                            <span class="ml-1" *ngIf="item.identifier">{{item.identifier}}</span>
                                            <span class="ml-1" *ngIf="item.id">({{item.id}})</span>
                                        </div>
                                    </div>
                                    <div class="right">
                                        <img class="corner-icon" [src]=setCornerIcon(item.access_type)>
                                    </div>
                                </div>
                                <div class="row p-1">
                                    <div class="col-4 d-flex">
                                        <img class="card-icon dp24" src="assets/icons/schedule_blue_24dp.svg">
                                        <div *ngIf="item.starttime">{{timeWindowToHours(item.starttime)}}</div>
                                        <div *ngIf="!item.starttime" class="red-text">XX:XX</div>
                                        -
                                        <div *ngIf="item.endtime">{{timeWindowToHours(item.endtime)}}</div>
                                        <div *ngIf="!item.endtime" class="red-text">XX:XX</div>
                                    </div>
                                    <div class="col-7 d-flex ml-5">
                                        <img class="card-icon dp24" src="assets/icons/today_blue_24dp.svg">
                                        <div *ngIf="item.starttime">{{timeWindowToDate(item.starttime)}}</div>
                                        <div *ngIf="!item.starttime" class="red-text">
                                            {{'fieldReporting.shared.missingDate'
                                            | translate }}</div>
                                    </div>
                                </div>
                                <div class="p-1 d-flex">
                                    <img class="dp24" src="assets/icons/location_on_blue_24dp.svg">
                                    <div class="mt-1">{{item.latitude}}, {{item.longitude}}</div>
                                </div>
                                <div class="p-1 d-flex">
                                    <div class="mt-1">{{ getStatusName(item.status_id) }}</div>
                                </div>
                                <hr>
                            </div>
                            <div *ngIf="item.notes || item.worker_notes">
                                <hr>
                                <div class="blue-text dual-container mb-2">

                                    <details class="w-100" (click)="expandArrowListener(item.id)">
                                        <summary class="dual-container">
                                            <div class="left d-flex">
                                                <img class="card-icon" src="assets/icons/info_blue_24dp.svg">
                                                <h6 class="m-0 lh-body">{{'fieldReporting.dashboard.extraRemarks' |
                                                    translate }}</h6>
                                            </div>
                                            <img id="arrowImg-{{item.id}}" class="right"
                                                src="assets/icons/expand_more_blue_24dp.svg">
                                        </summary>
                                        <div class="ms-4 mt-2">
                                            <div [hidden]="!item.worker_notes">
                                                <div>{{'fieldReporting.shared.extraInformationCallingService' |
                                                    translate }}
                                                </div>
                                                <p class="gray-text">{{item.worker_notes}}</p>
                                            </div>
                                            <div [hidden]="!item.notes">
                                                <div>{{'fieldReporting.shared.extraInformationPlanner' | translate }}
                                                </div>
                                                <p class="gray-text">{{item.notes}}</p>
                                            </div>
                                        </div>
                                    </details>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="pt-2" *ngIf="this.workOrdersLoading">
                    <div class="ms-5 pb-2">{{'fieldReporting.dashboard.loading' | translate }}</div>
                </div>
                <div class="pt-2" *ngIf="displayedWorkOrders.length == 0 && !this.workOrdersLoading">
                    <div class="ms-5 pb-2">{{'fieldReporting.dashboard.noWorkordersFound' | translate }}</div>
                </div>
            </div>
        </div>
    </div>
</div>
