import { Component, HostListener, OnInit } from '@angular/core';
import { UserService } from '@shared/services/user/user.service';
import { HttpClient } from "@angular/common/http";
import { Observable, Subject, switchMap, throwError } from "rxjs";
import { finalize, map, tap } from 'rxjs/operators';
import { PlannerProjectService } from "@modules/planner/services/planner-project/planner-project.service";
import 'leaflet-draw';
import { Project } from "@shared/models/project";
import { MapConfiguratorService } from "@shared/services/mapConfigurator/map-configurator.service";
import {
  CreateBasePayload,
  CreateRoutePayload,
  ROUTE_PLANNER_DRAW_OPTIONS, ROUTE_PLANNER_POLYLINE_COLOR, ROUTE_PLANNER_POLYLINE_HIGHLIGHT_COLOR,
  ROUTE_PLANNER_POLYLINE_SHAPE_OPTIONS,
  RoutePlanningActions,
  RoutePlanningBase,
  RoutePlanningRoute
} from "@shared/models/route-planning";
import { ToastService } from "@shared/services/toast/toast.service";
import { TranslateService } from "@ngx-translate/core";
import { RoutePlanningService } from "@modules/planner/services/route-planning/route-planning.service";
import { UserDetails } from "@shared/models/user";

declare const L: any;

@Component({
    selector: 'app-route-planning.ts',
    templateUrl: './route-planning.component.html',
    styleUrls: ['./route-planning.component.scss'],
    providers: [HttpClient]
})
export class RoutePlanningComponent implements OnInit {

  private componentDestroyed$: Subject<void> = new Subject()
  private projectId!: number;
  private userId!: number;
  private projectName!: string;

  private map!: L.Map;
  private customerId: number | undefined = 0;
  private drawPolyLineTool: any;
  private createNewRoute: RoutePlanningRoute | null = null;
  private baseMarkers: Map<number, L.Marker> = new Map();
  private routePolyLines: Map<number, L.Layer> = new Map();
  private blockMarker!: L.Marker;

  public activeAction: RoutePlanningActions = RoutePlanningActions.NONE;
  public actions = RoutePlanningActions;
  public pageLoading = true;

  constructor(
    private userService: UserService,
    private plannerProjectService: PlannerProjectService,
    private mapConfiguratorService: MapConfiguratorService,
    private toastService: ToastService,
    private translateService: TranslateService,
    private routePlanningService: RoutePlanningService,
  ) {}

  ngOnInit() {
    this.init$()
    .pipe(finalize(() => (this.pageLoading = false)))
    .subscribe({
      next: (project) => {
        if (!project || !this.customerId) {
          this.toastService.sendToast(false, this.translateService.instant('planner.routePlanning.errorInit'));
          return;
        }

        this.map = this.mapConfiguratorService.initMap(this.customerId, ROUTE_PLANNER_DRAW_OPTIONS);
        this.onDrawCreatedListener();
        this.onMapClickedListener();
        this.getBases(this.projectId);
        this.getRoutes(this.projectId);
        this.createBlockMarker();
      },
      error: () => {
        this.toastService.sendToast(false, this.translateService.instant('planner.routePlanning.errorInit'));
      }
    });
  }

  private init$(): Observable<Project | null> {
    return this.getCurrentProjectId$().pipe(
      switchMap((projectId) =>
        projectId !== undefined && projectId !== null
          ? this.plannerProjectService.getProjectById(projectId)
          : throwError(() => new Error('Project ID is undefined'))
      )
    );
  }

  private getCurrentProjectId$(): Observable<number | undefined> {
    return this.userService.getUserInfo().pipe(
      tap((user: UserDetails) => {
        this.projectId = user.current_project;
        this.userId = user.id;
        this.customerId = user.customer_id;
      }),
      map(user => user.current_project)
    );
  }

  private onDrawCreatedListener(): void {
    this.map.on('draw:created', (event: any) => {
      if (!event) return;

      // only create if using ENTER
      if (this.activeAction === RoutePlanningActions.DRAW_ROUTE && !this.createNewRoute) {
        this.cancelAction();
        return;
      }

      const layer = event.layer as L.Layer & { routeId?: number };
      this.mapConfiguratorService.getEditableLayers().addLayer(layer);
      if (layer.routeId) this.routePolyLines.set(layer.routeId, layer);

      this.createNewRoute = null;
      this.cancelAction();
    });
  }

  private getBases(projectId: number): void {
    this.routePlanningService.getBases(projectId)
    .subscribe(bases => {
      bases.forEach(base => {
        this.createBaseIcon(base);
      });
    });
  }

  private getRoutes(projectId: number): void {
    this.routePlanningService.getRoutes(projectId)
    .subscribe(routes => {
      routes.forEach(route => {
        this.createRoutePolyline(route);
      });
    });
  }

  @HostListener('document:keydown.enter', ['$event'])
  onEnterKeyPress(event: KeyboardEvent): void {
    if (this.activeAction === RoutePlanningActions.DRAW_ROUTE) {
      this.finishRouteDraw();
    }
  }

  @HostListener('document:keydown.escape', ['$event'])
  onEscKeyPress(event: KeyboardEvent): void {
    if (this.activeAction === RoutePlanningActions.DRAW_ROUTE
        || this.activeAction === RoutePlanningActions.CREATE_BASE
    ) {
      this.cancelAction();
    }
  }

  private resetHighlight(): void {
    const allLayers = this.mapConfiguratorService.getLayers();
    const polyLines = allLayers.filter(layer => layer instanceof L.Polyline);
    polyLines.forEach((polyLine: any) => polyLine.setStyle({
      color: ROUTE_PLANNER_POLYLINE_COLOR
    }));
  }

  private highlightPolyLine(selectedPolyLine: L.Polyline): void {
    this.resetHighlight();
    selectedPolyLine.setStyle({ color: ROUTE_PLANNER_POLYLINE_HIGHLIGHT_COLOR });
  }

  /**
   * Get the active polygon points from PolyLine tool and manually fire draw:created
   * @private
   */
  private finishRouteDraw(): void {
    if (this.drawPolyLineTool) {
      const points = this.drawPolyLineTool._poly.getLatLngs();
      if (points?.length <= 1) {
        this.toastService.sendToast(
          false,
          this.translateService.instant('planner.routePlanning.errorNotEnoughPoints'),
          null,
        );

        return;
      }

      const data: CreateRoutePayload = {
        project_id: this.projectId,
        coordinates: points,
      };

      this.routePlanningService.createRoute(data)
      .subscribe({
        next: (route) => {
          const polylineLayer = L.polyline(points, ROUTE_PLANNER_POLYLINE_SHAPE_OPTIONS)
          .bindPopup(this.getRoutePopUp(route.id))
          .on('click', () => {
            this.highlightPolyLine(polylineLayer);
          });

          polylineLayer.routeId = route.id;
          this.registerRouteDeleteEvent(polylineLayer);

          this.createNewRoute = route;
          this.map.fire('draw:created', {
            layer: polylineLayer,
            layerType: 'polyline',
          });

          this.drawPolyLineTool.disable();
          this.toastService.sendToast(
            true,
            this.translateService.instant('planner.routePlanning.successRouteDrawn'),
            null,
            this.translateService.instant('planner.routePlanning.routeDrawn')
          );
        },
        error: () => {
          this.drawPolyLineTool.disable();
        }
      });
    }
  }

  private onMapClickedListener(): void {
    this.map.on('click', (event: L.LeafletMouseEvent) => {
      if (this.activeAction === RoutePlanningActions.CREATE_BASE) {
        this.createBase(event.latlng);
      } else if (this.activeAction === RoutePlanningActions.DRAW_ROUTE) {
        this.blockMarker.setLatLng([event.latlng.lat, event.latlng.lng]);
      } else {
        this.resetHighlight();
      }
    });
  }

  private createBase(point: L.LatLng): void {
    const data: CreateBasePayload = {
      project_id: this.projectId,
      lat: point.lat,
      lng: point.lng,
      note: '',
    };

    this.routePlanningService.createBase(data)
    .subscribe({
      next: (base) => {
        this.cancelAction();
        this.createBaseIcon(base);
        this.toastService.sendToast(
          true,
          this.translateService.instant('planner.routePlanning.successBaseCreated'),
          null,
          this.translateService.instant('planner.routePlanning.baseCreated')
        );
      },
      error: (err) => {
        this.cancelAction();
      },
    });
  }

  private setAction(action: RoutePlanningActions): void {
    this.activeAction = action;
  }

  enableCreateBase(): void {
    this.setAction(RoutePlanningActions.CREATE_BASE);
  }

  cancelAction(): void {
    this.setAction(RoutePlanningActions.NONE);
    this.blockMarker.remove();
    if (this.drawPolyLineTool) this.drawPolyLineTool.disable();
  }

  enableDrawRoute(): void {
    this.blockMarker.addTo(this.map);
    this.blockMarker.setLatLng({ lat: 0, lng: 0 });
    this.setAction(RoutePlanningActions.DRAW_ROUTE);
    const drawControl = this.mapConfiguratorService.getDrawControl();
    this.drawPolyLineTool = new L.Draw.Polyline(this.map, drawControl.options.draw.polyline);
    this.drawPolyLineTool.enable();
  }

  /**
   * Create a blocking marker that prevents clicks to the map
   * Used to prevent polyline tool from creating a line when clicking the last point
   * @private
   */
  private createBlockMarker(): void {
    const point = new L.LatLng(0, 0);

    const transparentIcon = L.icon({
      iconUrl: '/assets/icons/powerlines/route_point.png',
      iconSize: [20, 20],
      iconAnchor: [10, 10],
    });

    this.blockMarker = L.marker(point, {
      icon: transparentIcon,
      zIndexOffset: 9999
    }).addTo(this.map);

    this.blockMarker.on('click', (e) => {
      L.DomEvent.stopPropagation(e);
    });
  }

  private createBaseIcon(base: RoutePlanningBase): void {
    const point = new L.LatLng(base.coordinates.lat, base.coordinates.lng);
    const customIcon = L.icon({
      iconUrl: '/assets/icons/powerlines/base.png',
      iconSize: [24, 24],
      iconAnchor: [12, 12],
      popupAnchor: [0, -20]
    });

    const marker = L.marker(point, { icon: customIcon }).addTo(this.map);
    this.baseMarkers.set(base.id, marker);

    const markerLayer = marker.bindPopup(`
      <div class="title">${this.translateService.instant('planner.routePlanning.base')} #${base.id}</div>
      ${base.notes ? '<div class="notes">' + base.notes + '</div>' : ''}
      <button class="btn btn-delete btn-danger" id="deleteBase">
        ${this.translateService.instant('basic.delete')}
      </button>
    `);

    markerLayer.on('popupopen', () => {
      const button = document.getElementById('deleteBase');
      if (button) button.addEventListener('click', () => this.deleteBase(base.id));
    });
  }

  private deleteBaseMarker(baseId: number): void {
    const marker = this.baseMarkers.get(baseId);
    if (marker) {
      this.map.removeLayer(marker);
      this.baseMarkers.delete(baseId);
    }
  }

  private deleteRoutePolyLine(routeId: number): void {
    const polyLine = this.routePolyLines.get(routeId);
    if (polyLine) {
      this.map.removeLayer(polyLine);
      this.routePolyLines.delete(routeId);
    }
  }

  private getRoutePopUp(routeId: number): string {
    return `
      <div class="title">${this.translateService.instant('planner.routePlanning.route')} #${routeId}</div>
      <button class="btn btn-delete btn-danger" id="deleteRoute">
        ${this.translateService.instant('basic.delete')}
      </button>
    `;
  }

  private registerRouteDeleteEvent(polyLineLayer: L.Polyline & { routeId: number }): void {
    polyLineLayer.on('popupopen', () => {
      const button = document.getElementById('deleteRoute');
      if (button) button.addEventListener('click', () => this.deleteRoute(polyLineLayer.routeId));
    });
  }

  private createRoutePolyline(route: RoutePlanningRoute): void {
    if (route?.coordinates?.length < 2) return;

    const polylineLayer = L.polyline(route?.coordinates, ROUTE_PLANNER_POLYLINE_SHAPE_OPTIONS)
    .bindPopup(this.getRoutePopUp(route.id))
    .on('click', () => {
      this.highlightPolyLine(polylineLayer);
    });

    polylineLayer.routeId = route.id;
    this.registerRouteDeleteEvent(polylineLayer);

    this.map.fire('draw:created', {
      layer: polylineLayer,
      layerType: 'polyline',
    });
  }

  private deleteRoute(routeId: number): void {
    this.routePlanningService.removeRoute({ project_id: this.projectId, route_id: routeId })
      .subscribe({
        next: () => {
          this.deleteRoutePolyLine(routeId);
          this.toastService.sendToast(
            true,
            this.translateService.instant('planner.routePlanning.successRouteDeleted'),
            null,
            this.translateService.instant('planner.routePlanning.routeDeleted')
          );
        },
        error: (err) => {
          this.toastService.sendToast(
            false, this.translateService.instant('planner.routePlanning.errorDeleteRoute'), null
          );
        }
      });
  }

  private deleteBase(baseId: number): void {
    this.routePlanningService.removeBase({ project_id: this.projectId, base_id: baseId })
    .subscribe({
      next: () => {
        this.deleteBaseMarker(baseId);
        this.toastService.sendToast(
          true,
          this.translateService.instant('planner.routePlanning.successBaseDeleted'),
          null,
          this.translateService.instant('planner.routePlanning.baseDeleted')
        );
      },
      error: (err) => {
        this.toastService.sendToast(
          false, this.translateService.instant('planner.routePlanning.errorDeleteBase'), null
        );
      }
    });
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next();
    this.componentDestroyed$.complete();
  }
}
