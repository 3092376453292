import { Injectable } from '@angular/core';
import { CustomerSettingsComponents } from "@shared/models/customer-settings";
declare const L: any;

@Injectable({providedIn: 'root'})
export class CustomerSettingsService {

  constructor() {}

  public getCenterCoordinates(
    customerId: number,
    componentName: CustomerSettingsComponents,
  ): { latitude: number, longitude: number } {
    if (componentName === CustomerSettingsComponents.RoutePlanning) {
      switch (customerId) {
        case 1: return { latitude: 60.18208069645202, longitude: 23.97548906518469 };
        case 2: return { latitude: 60.03611032213076, longitude: 18.54047482522265 };
        case 3: return { latitude: 60.64223081373168, longitude: 25.27908413017419 };
        case 4: return { latitude: 62.1250521303749, longitude: 27.7926603631823 };
        default: return { latitude: 0, longitude: 0 };
      }
    }

    return { latitude: 0, longitude: 0 }
  }
}
